import dynamic from "next/dynamic";

const Markdown = dynamic(() => import("./Markdown"));

const isLaTeX = / /.test.bind(
  /\\(documentclass|usepackage|begin|end|section|subsection|title|author|date|maketitle|textbf|textit|item|cite|ref|label|includegraphics|frac|sum|alpha|beta|gamma|delta|epsilon|theta|lambda|mu|pi|rho|sigma|phi|omega|text|\[|\]|\(|\)|\^|_|\\|,|\s)*(\$|\{|\}|\\\()|(\$.*?\$)|(\$\$.*?\$\$)/
);

const RenderPaperTitle = ({ paperTitle }) =>
  isLaTeX(paperTitle) ? <Markdown paperTitle={paperTitle} /> : paperTitle;

export default RenderPaperTitle;
