import { forwardRef } from "react";
import Link from "next/link";

export const NextLink = forwardRef(function NextLink(props, ref) {
  return <Link ref={ref} scroll {...props} />;
});

export const ShallowLink = forwardRef(function ShallowLink(props, ref) {
  return <Link replace shallow scroll={false} ref={ref} {...props} />;
});
